
import * as ipxRuntime$XlQH0GJJGw from '/home/runner/work/divine/divine/node_modules/.pnpm/@nuxt+image@1.8.1_@azure+storage-blob@12.25.0_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as twicpicsRuntime$jGDveujV1Q from '/home/runner/work/divine/divine/node_modules/.pnpm/@nuxt+image@1.8.1_@azure+storage-blob@12.25.0_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/providers/twicpics'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "small": {
      "modifiers": {
        "width": 80,
        "height": 80,
        "quality": 90
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "divineteststoragehot.blob.core.windows.net",
    "divinesamples.twic.pics"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$XlQH0GJJGw, defaults: undefined },
  ['twicpics']: { provider: twicpicsRuntime$jGDveujV1Q, defaults: undefined }
}
        